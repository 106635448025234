import { createApp } from "/node_modules/.vite/deps/vue.js?v=0961ca4b";
import App from "/src/App.vue";
import router from "/src/router/index.js";
import "/src/styles/main.scss";

// Prime
import PrimeVue from "/node_modules/.vite/deps/primevue_config.js?v=6a104488";
import "/node_modules/primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import Button from "/node_modules/.vite/deps/primevue_button.js?v=2a54ed57";
import InputText from "/node_modules/.vite/deps/primevue_inputtext.js?v=45f226ea";
import FloatLabel from "/node_modules/.vite/deps/primevue_floatlabel.js?v=76a8253f";
import Ripple from "/node_modules/.vite/deps/primevue_ripple.js?v=cc630d48";
import BadgeDirective from "/node_modules/.vite/deps/primevue_badgedirective.js?v=df0fec66";

import Tooltip from "/node_modules/.vite/deps/primevue_tooltip.js?v=e214768a";
import ToastService from "/node_modules/.vite/deps/primevue_toastservice.js?v=505be15b";
import ConfirmationService from "/node_modules/.vite/deps/primevue_confirmationservice.js?v=e10327c8";

import "/node_modules/primevue/resources/themes/mdc-light-deeppurple/theme.css";

//services
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=807f7003";
const pinia = createPinia();

const app = createApp(App);
app.use(PrimeVue, {
  ripple: true
});

app.use(router);
app.use(pinia);
app.use(ToastService);
app.use(ConfirmationService);
app.directive("tooltip", Tooltip);
app.directive("ripple", Ripple);
app.directive("badge", BadgeDirective);

//components
app.component("TheButton", Button);
app.component("InputText", InputText);
app.component("FloatLabel", FloatLabel);
app.mount("#app");
export const toast = app.config.globalProperties.$toast;
